<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Attach File List')"></tw-header>
    <div class="tw_container list_attach_file">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
            <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
            <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.File Name')" prop="attachFileName">
            <el-input type="text" v-model="searchForm.attachFileName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item class='max-content' :label="$t('Label.Attach Datetime')" prop="attachDatetime">
            <el-date-picker
              v-model="searchForm.attachDatetime"
              type="datetimerange"
              range-separator="to"
              format='yyyy-MM-dd HH:mm'
              value-format='yyyy-MM-dd HH:mm:ss'
              :default-value="defaultDate"
              :default-time="['00:00:00', '23:59:59']"
              @change.native="onDateRangeChange"
              start-placeholder="Start datetime"
              end-placeholder="End datetime">
            </el-date-picker>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
                <el-input type="text" v-model.trim="searchForm.tradingFlowId" class="trading-flow" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
                <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Flow Name')" prop="tradingFlowName">
                <el-input type="text" v-model="searchForm.tradingFlowName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Category')" prop="categoryType">
                <el-select popper-class="category-select" v-model="searchForm.categoryType" :class="{multiple_selected: multipleSelect(searchForm.categoryType)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="category in categories"
                    :key="category.code"
                    :label="category.name"
                    :value="category.code"
                  >
                    <tw-entity-icon :entityName="category.entityId" size="medium" color="color" style="margin-right: 8px;transform: translateY(4px);" />
                    <span>{{category.name}}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.Process')" prop="processType">
                <el-cascader popper-class="category-select" v-model="searchForm.processType" :class="{multiple_selected: multipleSelect(searchForm.processType)}" placeholder="Select" :options="processes" :props="{value: 'code', 'label': 'name', multiple: true}" collapse-tags clearable>
                  <template slot-scope="{ data }">
                    <div style="display: flex;align-items: center">
                      <tw-entity-icon v-if="data.entityId" :entityName="data.entityId" size="medium" color="color" style="margin-right: 8px;" />
                      <span>{{data.name}}</span>
                    </div>
                  </template>
                </el-cascader>
              </el-form-item>
              <el-form-item :label="$t('Label.Status')" prop="processProgressStatus">
                <el-select v-model="searchForm.processProgressStatus" :class="{multiple_selected: multipleSelect(searchForm.processProgressStatus)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label=" " prop="fileUploadUser">
                <el-checkbox border class="border_none" v-model="searchForm.fileUploadUser" :true-label="ATTACHED_FILE_FLG.ON" :false-label="ATTACHED_FILE_FLG.OFF">{{$t('Label.My File Only')}}</el-checkbox>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <div>
            <tw-button
               type="primary"
               size="medium"
               :disabled="selectionItems.length <= 0"
               @click="download"
               class="delete_button"
               style="margin-right: 16px"
             >
               Download
            </tw-button>
            <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
              <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
              <el-dropdown-menu class="nowrap">
                <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-form>

      <tw-table-system
        :schemas="schemas"
        :items="items"
        :pageSize="limit"
        :totalCount="totalCount"
        :offset="offset"
        :selection="true"
        :serverPagination="true"
        :rowKey="getRowKey"
        @selection-change="selectionChange"
        @row-click="rowClick"
        @row-dbclick="rowDbClick"
        @link-click="processLink"
        @paging="onPaging"
        ref="table"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { ATTACH_FILE_SEARCH_SORT_TARGET, ATTACHED_FILE_FLG, BASE64_FLG, INITIAL_VIEW_FLG, PROCESS_PROGRESS_STATUS, PROCESS_PROGRESS_STATUS_VARIABLES } from 'lib-tw-common';
// import dayjs from 'dayjs';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { collapseTransition } from '@/utils/nextFrame';
import { entityNames } from '@/dictionaries/map.js';
import { normalize, clearSearchForm, formatUtcDate } from '@/utils/searchUtil';
import schemas from '@/dictionaries/listAttachFileSchema.json';

export default {
  name: 'ListAttachFile',
  mixins: [mixinEntityList, collapseTransition],
  data() {
    return {
      schemas: schemas,
      items: null,
      // エンティティプルダウン
      categories: _.map(entityNames, o => {
        return {
          ...o,
          name: this.$t(`Entity.${o.nameEn}`)
        };
      }),
      // プロセスプルダウン
      processes: [],
      searchForm: {
        fromSectionIds: [],
        toSectionIds: [],
        tradingName: '',
        tradingFlowId: '',
        tradingFlowName: '',
        categoryType: [],
        processType: [],
        processProgressStatus: [],
        attachFileName: '',
        attachDatetime: [],
        fileUploadUser: ATTACHED_FILE_FLG.OFF,
      },
      ATTACHED_FILE_FLG: ATTACHED_FILE_FLG,
      limit: 200,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      selectionItems: [],
      maxSelect: 20,
      isDownLoading: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Attach File List')},
      ];
    },
    PROCESS_PROGRESS_STATUS_VARIABLES() {
      const options = [
        // PROCESS_PROGRESS_STATUS.NOT_STARTED, // NOTE: 添付している場合はNOT_STARTED状態ではないため除外
        PROCESS_PROGRESS_STATUS.F_INPUT_IN_PROGRESS,
        PROCESS_PROGRESS_STATUS.F_AWAITING_APPROVAL,
        PROCESS_PROGRESS_STATUS.T_AWAITING_CONFIRMATION,
        PROCESS_PROGRESS_STATUS.T_AWAITING_APPROVAL,
        PROCESS_PROGRESS_STATUS.DONE
      ];
      return _.filter(PROCESS_PROGRESS_STATUS_VARIABLES, o => _.includes(options, o.code));
    },
  },
  created() {
    this.processes = this.getProcesses();
    const queries = this.$store.getters.getQueries('ATTACH_FILE');
    if (queries) {
      this.searchForm = queries;
    }
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        tradingFlowName: f.tradingFlowName,
        categoryType: f.categoryType ? f.categoryType : [],
        processType: f.processType ? _.map(f.processType, item => item[1]) : [],
        processProgressStatus: this.getProcessProgressStatus(f.processProgressStatus),
        attachFileName: f.attachFileName,
        attachCreateDateTimeFrom: f.attachDatetime ? formatUtcDate(f.attachDatetime[0]) : null,
        attachCreateDateTimeTo: f.attachDatetime?.[1] ? formatUtcDate(f.attachDatetime[1]).replace('00Z', '59Z') : null,
        fileUploadUser: f.fileUploadUser,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_af_1 添付ファイル一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/attach-file/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = res.attachFileList;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      this.selectionItems = [];
      this.items = null;
      this.$store.commit('SET_QUERIES', {key: 'ATTACH_FILE', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.searchForm.fileUploadUser = ATTACHED_FILE_FLG.OFF;
      this.$store.commit('SET_QUERIES', {key: 'ATTACH_FILE', queries: _.cloneDeep(this.searchForm)});
    },
    rowClick(row, column) {
      if (column.type === 'LinkColor') return;
      this.$refs.table.toggleRowSelection(row);
    },
    // eslint-disable-next-line no-unused-vars
    rowDbClick(row) {
      return;
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [ATTACH_FILE_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_af_1 添付ファイル一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/attach-file/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.attachFileList);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => normalize(str).includes(normalize(keyword)));
    },
    // 検索の初期化フラグを返却します
    // eslint-disable-next-line no-unused-vars
    initialFlag(queries) {
      // 当分の間初期検索条件はなし
      return INITIAL_VIEW_FLG.OFF;
      // return _.every(queries, (value, key) => {
      //   if (key === 'latestVersionOnly') {
      //     return !value;
      //   }
      //   return !_.isNumber(value) && _.isEmpty(value);
      // }) ? 1 : 0;
    },
    getRowKey(row) {
      return row.documentManageId;
    },
    // セレクト切り替え
    selectionChange(multipleSelection) {
      this.selectionItems = multipleSelection;
      if (multipleSelection.length > this.maxSelect) {
        // 21件以上選択した状態で押下時、以下アラート表示
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[20] exceeded.');
        const lastSelectItem = _.last(multipleSelection);
        this.$refs.table.toggleRowSelection(lastSelectItem, false);
      }
    },
    download(row) {
      // console.log(this.selectionItems)
      if (this.isDownLoading) return;
      this.isDownLoading = true;
      let documentManageId
      if (row) {
        // File Nameクリック
        documentManageId = [row.documentManageId];
      } else {
        // 選択中のアイテムが複数
        documentManageId = _.map(this.selectionItems, item => {
          return item.documentManageId;
        })
      }
      const query = {
        base64Flg: BASE64_FLG.OFF,
        documentManageId: documentManageId,
      }
      // console.log(query)
      const fileName = `Attach.zip`;
      // bff_af_2 添付ファイルダウンロードBFF
      const apiCode = 'get_/v1/attach-file/download';
      const serviceCode = 'tw-transaction-bff-api';
      const getFileNameFromHeaders = 'content-disposition';
      $api
      .txtDownload(query, fileName, serviceCode, apiCode, getFileNameFromHeaders)
      .then(() => {
        this.$refs.table.clearSelection();
        this.isDownLoading = false;
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
        this.isDownLoading = false;
      });
    },
    processLink(row) {
      this.$router.push({
        name: `Process${_.capitalize(row.processId.replace(/\d/g, ''))}`,
        params: { entityId: row.entityId, processSeq: row.processSeq, _processId: row.processId.toLowerCase() },
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.search_unit .max-content {
  width: max-content;
}
</style>

<style lang="scss">
.tw_container {
  &.list_attach_file {
    // selection 全選択チェックボックスは非表示
    .el-table__header thead .el-table-column--selection {
      .cell {
        display: none;
      }
    }
  }
}
</style>